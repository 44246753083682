<template>
  <table class="table" style="width:100%; ">
    <tr style="font-weight: bold;">
      <td><span style="color: #ff7f50;">&#9654;</span> 등록</td>
      <td></td>
      <td></td>
      <td>
        <input style="max-width:50px;float:right;" type="button" v-on:click="click('INSERT_NOTICE')" value="등록" />
      </td>
    </tr>
    <tr>
      <td colspan="4">
        <noticec ref="insertNotice"/>
      </td>
    </tr>    
    <br>
    <tr style="font-weight: bold;">
      <td><span style="color: #ff7f50;">&#9654;</span>  조회</td>
      <td></td>
      <td></td>
      <td>
        <input style="max-width:50px;float:right;margin-left: 10px;margin-top:3px" type="button" v-on:click="click('SELECT_NOTICE')" value="조회" />
        <select ref="notice_type" style="float:right;text-align: center;padding:5px;" @change="click('change')">  
          <option value="all">ALL</option>
          <option value="popup">popup</option>
          <option value="youtube">youtube</option>
          <option value="mypage">mypage</option>
          <option value="lottoNum">lottoNum</option>
          <option value="innomax">innomax</option>
          <option value="policy">policy</option>
          <option value="hscode">hscode</option>
          <option value="chatbot-qa">chatbot-qa</option>
          <option value="chatbot-recm">chatbot-recm</option>
          <option value="articles">articles</option>
          <option value="coinPeriod">coinPeriod</option>
        </select>
      </td>
    </tr>
  </table>
  <div v-if="noticeList.length === 0" class="no-result"> 
      조회 내역이 없습니다.
  </div>
  <div v-else style="text-align:center">
    <Accordion value="0">
      <AccordionPanel :value="index+''" v-for="(notice, index) in noticeList" :key="index" >
        <AccordionHeader :class="index%2===1 ? 'datatable-background' : ''"> 
          <div style="width:90%;" class="div_title2">&nbsp;{{notice.SEQ}}.&nbsp; {{notice.TITLE}}  <label v-if="notice.USE_YN === true">사용중</label> </div>
        </AccordionHeader>
        <AccordionContent>
          <noticec @noticeClick="click" :content=notice.CONTENT :title=notice.TITLE :seq=Number(notice.SEQ) :type=notice.TYPE :priority=Number(notice.PRIORITY) :useYn=notice.USE_YN :noticeYn=notice.NOTICE_YN class="content" :key="noticecKey"/>
        </AccordionContent>
      </AccordionPanel>
    </Accordion>
  </div>
  <div v-if="noticeList.length !== 0" class="div_center" >
    <label @click="paging('prev')" class="link" >
      이전
    </label>
    <span style="margin:20px">{{ page }}</span>
    <label @click="paging('next')" class="link">
      다음
    </label>
  </div>
</template>
<script>
import noticec from '../content/notice_content.vue'

export default {
  components: {
    noticec
  },  
  mounted() {
  },
  data() { 
    return { 
      page:1,
      noticeList: Array(),
      noticecKey:-1
    }
  },
  methods: {
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      let noticeType = this.$refs.notice_type.value
      if(noticeType === 'all') noticeType = undefined
      await this.api.axiosGql('SELECT_NOTICE', {type:'SELECT_NOTICE', noticeType: noticeType, useYn:true, page:this.page})
      .then( async (result) => {
        if(result.data.length === 0) {
          this.page -= 1
          alert('마지막 페이지입니다.')
        } else {
          this.noticeList = result.data
          this.noticecKey++
        }
      })
      .catch( (err) => {
        console.log(err)
      })
    },
    async click(type) {
      if(type === 'SELECT_NOTICE') {
        let noticeType = this.$refs.notice_type.value
        if(noticeType === 'all') noticeType = undefined
        await this.api.axiosGql(type, {type:type, noticeType: noticeType, useYn:undefined, page:1})
        .then( async (result) => {
          if(result.data.length === 0) {
            this.page -= 1
            alert('마지막 페이지입니다.')
          } else {
            this.noticeList = result.data
            this.noticecKey++
          }
        })
        .catch( (err) => {
          console.log(err)
        })
      } else if(type === 'INSERT_NOTICE') {
        const insertNotice = this.$refs.insertNotice
        const useYn = insertNotice.$refs.useYn.checked
        const noticeYn = insertNotice.$refs.noticeYn.checked
        const priority = insertNotice.$refs.priority.value
        const title = insertNotice.$refs.title.value
        const content = insertNotice.$refs.content.value
        const noticeType = insertNotice.$refs.type.value
        if(title === '' || content === '' || noticeType ==='') this.$notify('필수 입력값이 누락되었습니다.')
        else {
          await this.api.axiosGql(type, {type:'INSERT_NOTICE', noticeType:noticeType, useYn:Boolean(useYn), noticeYn:Boolean(noticeYn), priority:Number(priority), title:title, content:content})
          .then( async (result) => {
            if(result === null || result === undefined) this.$notify('다시 시도해주세요.')
            else {
              this.$notify('등록되었습니다.')
              this.click('SELECT_NOTICE')
            }
          }).catch((err) => console.log(err) )
        }
      } else if(type === 'change') {
        this.page = 1
        this.click('SELECT_NOTICE')
      }
    },
  }
}
</script>

<style scoped>
.datatable-background {
  background-color:#f9f9f9;
}
</style>